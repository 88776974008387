@import '../../Scss/variables.scss';
@import '../../Scss/mixins.scss';


.component-section-one-home {
  max-width:100%;
  margin-bottom:50px;
  min-height:400px;
  margin-top:40px;
  position:relative;

  .link-section-wrapper {
      display: flex;
      flex-wrap: wrap;
      width: 70%;
      margin: auto;
      position:relative;
      animation:animIn .5s 1;
      animation-fill-mode:forwards;
      opacity:0;
      margin-bottom:200px;

      @media(max-width:$large_tablet){
        width:95%;
        margin-bottom:100px;
      }


      @keyframes animIn {
        0%{
          top:35px;
        }
        100%{
          top:0;
          opacity:1;
        }
      }

      .Tilt {
          flex-basis: 50%;

         &:hover {
              box-shadow: 0px 0px 99px -26px rgba(0, 0, 0, 0.75);
              z-index:9999;

            }
      }

      a {
        flex: 1 0 calc(50% - 10px);
        display: flex;
        align-items: center;
        justify-content: center;
        display: flex;
        align-items: center;
        justify-content: center;
        background-size:80%;
        background-position: center;
        background-repeat: no-repeat;
        position:relative;
        @include font-size(7);
        text-decoration:none;
        color:$default;
        transition:.4s;

       

        &:after {
          content: '';
          display: block;
          padding-bottom: 100%;
        }
        &:hover {
           @include font-size(7);
           transition:.4s;
        }



        @media(max-width:$large_tablet){
          width:100%;
          background-size:80%;
          flex: 1 0 calc(100% - 10px);
          @include font-size(4.5);
        }

        span.filter {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            transition:1s;
            height:0%;
            background:#eee;
            z-index:0;

        }
        span.menu-item {
          z-index:2;
          position:relative;
        }

        &:hover span.filter {
          height:100%;
          transition:height .5s;
          background:#eee;
        }
      
        &:hover  {
          color:$primary;
          transition:.3s;
          animation:svgBlink .95s infinite;
          animation-delay:.3s;


          @keyframes svgBlink {
            0%{
              color:$primary;
            }
            50%{
              color:$secondary;
            }
            100%{
              color:$primary;
            }
          }

        }
        svg {
          width: 250px;
          fill: #fff;
          transition:1s;
          z-index:2;
        }
    }
    .about,.contact {
      background-color:$primary;
    }
    .menu,.facebook {
      background-color:$secondary;


    }
     @media(max-width:$large_tablet){
      .facebook {
        background-color:$primary;
        }
      .contact {
        background-color:$secondary;
      }
    }
  }

}