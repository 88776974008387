body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image:url('./Media/svg/BACKGROUND_TEXTURE.svg');
  width: 100%;
    min-height: 100vh;
    background-size:cover;
    background-position:center;
}

@font-face {
  font-family: HuggyBold;
  src: url("./Media/Fonts/Huggy-Bold.otf");
}
@font-face {
  font-family: HuggyRegular;
  src: url("./Media/Fonts/Huggy-Regular.otf");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,h2,h3,h4,h5,h6 {
  font-family:"HuggyBold";
}

input[type="submit"],button {
  -webkit-appearance: none;
-moz-appearance: none;
appearance: none;
}
textarea {
  font-size:15px;
  font-size:1.3rem !important;
}

h2 {
  font-size: 5.5rem;
    text-transform: uppercase;
    margin-top: 50px;
    color:#FF8672;
    margin-bottom: 0;
}
.weight-bold {
  font-family:"HuggyBold";
}
p,li,span{
  font-family:"HuggyRegular";
  font-weight:100;
  line-height:140%;
}
label {
  font-family:"HuggyRegular";
  letter-spacing:1px;
  font-weight:100;

}

.submit-button {
  font-family:"HuggyRegular";
  font-size:3rem;
}
.fish-chips {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    display: flex;
    align-items: center;
    justify-content: center;
}
.fish-chips div {
  width: 100%;
    height: 70%;
    background-image: url('./Media/png/fish_back_v2.png');
    background-position: center;
    background-size: cover;
    background-attachment:fixed;
}
.menu-section-wrapper {
  background-image:url('./Media/png/twin-texture-trans.png');
  background-size:cover;
  background-position:center;
}
#mc_embed_signup #mc-embedded-subscribe-form div.mce_inline_error {
  margin-bottom:0 !important;
  animation:errorIn .5s 2;
  animation-fill-mode:forwards;
}

@keyframes errorIn {
  0%{
    opacity:1;
  }
  50%{
    opacity:0;
  }
  100%{
    opacity:1;
  }
}

.pop-up-container.pop-hide {
   animation:popOut 1.2s 1;
  animation-fill-mode:forwards;
  animation-timing-function:ease-in;
}


@keyframes popOut {
  0%{
    right:0%;
  }
  100%{
    right:-100%;
  }
}

.pop-up-container {
    position: fixed;
    bottom: 0;
    width: 400px;
    border-top: 2px solid #ff8672;
    border-left: 2px solid #ff8672;
    right:-100%;
    animation:popIn .6s 1;
    animation-fill-mode:forwards;
    animation-delay:1s;

}

@keyframes popIn {
  0%{
    right:-100%;
  }
  25%{
    right:0;
  }
  50%{
    right:-5%;
  }
  100%{
    right:0;
  }
}
#mc_embed_signup .mc-field-group input {
    display: block;
    width: 100%;
    padding: 8px 0;
    text-indent: 2%;
    border: none;
    border-radius: 40px;
    height: 45px;
    text-indent: 5px;
    box-sizing: border-box;
    padding: 5px;
    background: #ff8672;
    border: none;
}
#mc_embed_signup h2 {
    font-weight: bold;
    padding: 0;
    margin: 15px 0;
    font-size: 1.4em;
    font-weight: lighter;
    letter-spacing: .5px;
    width: 80%;
    margin-left:15px !important;
}
#mc_embed_signup input {
  color:#fff;
  font-size:16px;
  font-weight:bold;
}
#mc_embed_signup .mc-field-group label {
    display: block;
    margin-bottom: 3px;
    color: #1675a9;
    text-transform: uppercase;
    position: relative;
    left: 15px;
}

#mc_embed_signup .button {
  background: #1675A9 !important;
    padding: 0 40px;
    width: calc(50% - 15px);
    margin-top: 20px;
    font-family:"HuggyRegular";
    border-radius:40px !important;
    color: #FF8672;
    font-weight: bold;
    font-size: 15px;
    font-size: 1.5rem;
    padding-top: 5px;
    letter-spacing: 1px;
    text-transform: uppercase;
    cursor: pointer;
    transition: 1s;
    width:95% !important;
}

.close-pop {
  cursor:pointer;
  float:right;
  margin-right:20px;
  margin-top:20px;
  position:relative;
  z-index:9;
  transition:.5s;
}
.close-pop:hover {
  transform:rotate(180deg);
  transition:.5s;
}

#mc_embed_signup .button:hover {
    background:#eee !important;
    transition:.3s;
    animation:submitBlink .65s infinite;

  }

    @keyframes submitBlink {
      0%{
        color:#1675A9;
      }
      50%{
        color:#FF8672;
      }
      100%{
        color:#1675A9;
      }
    }

    #mc_embed_signup #mce-success-response {
      color: #1575a9 !important;
      display: none;
      margin-bottom: 15px;
      padding-top: 0;
      marin-top: 0;
      margin-top: 0;
      padding-bottom: 0;
      text-transform: uppercase;
      font-family: "HuggyRegular";
      letter-spacing: 1px;
      left:15px;
      opacity:0;
      animation:sucessIn .5s 2;
      animation-fill-mode:forwards;
  }

  @keyframes sucessIn {
    0%{
      left:15px;
      opacity:0;
    }
    100%{
      left:0;
      opacity:1;
    }
  }

  div#mce-error-response {
    color: #1575a9;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
}
div#mce-error-response  a {
  color:#ff8671;
}

@media(max-width:1100px){
  .fish-chips div {
    background-attachment:initial;
  }
  h2 {
    font-size:17vw;
  }
}

@media(max-width:650px){
  .pop-up-container {
    width:100%;
  }
  textarea {
    font-size:16px !important;
  }
  .full-bleed-pop h4 {
    text-align: left;
    padding-right: 50px;
    font-size: 20px;
    padding-left: 15px;
  }
}