@import '../../Scss/variables.scss';
@import '../../Scss/mixins.scss';


.about-page {
  .about-wrapper {
    max-width:70%;
    text-align:center;
    margin:auto;
    position:relative;
    animation:aboutIn .6s 1;
    animation-fill-mode:forwards;

    @media(max-width:$large_tablet){
      max-width:95%;
    }

    @keyframes aboutIn {
      0%{
        top:45px;
      }
      100%{
        top:0;
      }
    }

    .heading {
      width:75%;
      margin:auto;
      margin-top:15px;

      @media(max-width:$large_tablet){
        width:95%;
      }

      img {
        width:70%;
      }
    }
    .content {
        width: 70%;
        margin: auto;
        margin-bottom:200px;
        margin-top:50px;

        @media(max-width:$large_tablet){
          width:95%;
          margin-bottom:100px;
        }

        img {
          width:100%;
          margin-top:0;
        }
        p {
          @include font-size(1.5);
          letter-spacing:1px;
          color:$primary;
          

        }
    }
  }
}