@import '../../Scss/variables.scss';
@import '../../Scss/mixins.scss';

.react-datepicker-popper {
  z-index:99;
}
 input[type="email"] {

    width:calc(100% - 15px);

  }

  .lds-ripple {
    position: relative;
    width: 40px;
    height: 40px;
    margin-top: 15px;
    opacity:0;
    transition:.5s;
}
.lds-ripple.active {
  opacity:1;
  transition:.5s;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid $primary;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

.calendar-wrapper {

  .non-active,.active {
  position:relative;
  transition:.5s;

  &:hover span.checkmark {
    background:$primary;
    transition:.5s;
  } 

  input[type="checkbox"] {
    height:100% !important;
    box-sizing:border-box !important;
    position:absolute;
    width:100%;
    height:100%;
    z-index:9;
    cursor:pointer;
    margin:0;

    &:hover {
      outline:1px solid #fff;
      box-sizing:border-box;
    }


  }


  .checkmark {
    position:absolute;
    width:100%;
    height:100%;
  }
}

.active {
  img {
    display:block !important;
    z-index:1;
    animation:tickIn .5s 1;
    animation-fill-mode:forwards;
    left:5px;
    position:relative;

    @keyframes tickIn {
      0%{
        opacity:0;
        left:5px;
      }
      50%{
        fill:#eee;
      }
      100%{
        opacity:1;
        left:0;
      }
    }
  }
}

}

/* calendar wrapper */
.calendar-wrapper {
    background: $secondary;
    height: auto;
    border-radius:20px;

    .row {
      height:45px;
      display:flex;

      div {
        flex-basis:12.5%;
        outline:.2px solid rgba(255,255,255,0.3);
        font-size:11px;
        color:#fff;
        display:flex;
        align-items:center;
        justify-content:center;
        font-weight:bold;
        position:relative;

        input {
          width:100%;
          height:100%;
          position:absolute;
          opacity:0;
        }

        img {
          width:14px;
          display:none;
        }
        img.show {
          display:block;
        }
      }
    }
}

// File upload and submit

.form-double-row.file-upload-and-submit {

   &:hover span {
  background: #eee;
  transition: .3s;
  animation: submitBlinkTwo .65s infinite;

    @keyframes submitBlinkTwo {
      0%{
        color:$primary;
      }
      50%{
        color:$secondary;
      }
      100%{
        color:$primary;
      }
    }
  }

  div {
    position:relative;
    flex-basis: 40% !important;
    display:flex;
    align-items:center;
    margin: 0 25px 0 0;

    @media(max-width:$mobile){
      flex-basis:100% !important;
    }

    span {
      padding: 0 40px;
    width: 100%;
    margin-top: 20px;
    color: $primary;
    font-weight: bold;
    font-size: 15px;
    font-size: 100%;
    padding-top: 5px;
    letter-spacing: 1px;
    text-transform: uppercase;
    cursor: pointer;
    transition: 1s;
    border: 2px solid $primary;
    border-radius: 40px;
    height: 45px;
    text-indent: 5px;
    box-sizing: border-box;
    padding-top: 3.5px;
    display: flex;
    align-items: center;
    justify-content: center;




  }

    input[type='file'] {
      position: absolute;
      width: 100% !important;
      height: 100%;
      padding: 0;
      z-index: 999;
      cursor: pointer;
      opacity:0;
      margin-top:20px;

          &:hover span{
      background: #eee;
      transition: .3s;
      animation: submitBlinkTwo .65s infinite;

        @keyframes submitBlinkTwo {
          0%{
            color:$primary;
          }
          50%{
            color:$secondary;
          }
          100%{
            color:$primary;
          }
        }
    }
  }
    input[type='submit']{
      background: $primary;
      padding: 0 40px;
      width: calc(50% - 15px);
      margin-top: 20px;
      color: #FF8672;
      font-weight: bold;
      font-size: 15px;
      font-size: 1.5rem;
      padding-top: 5px;
      letter-spacing: 1px;
      text-transform: uppercase;
      cursor: pointer;
      transition: 1s;
      border: none;
      border-radius: 40px;
      height: 45px;
      text-indent: 5px;
      box-sizing: border-box;


      &:hover {
        background: #eee;
        transition: .3s;
        animation: submitBlink .65s infinite;

        @keyframes submitBlink {
          0%{
            color:$primary;
          }
          50%{
            color:$secondary;
          }
          100%{
            color:$primary;
          }
        }
      }
    }
  }

}

.residency-wrapper {

 
      display: flex;
      align-items: center;
      /* justify-content: center; */
      
      div {
        position:relative;

        @media(max-width:$mobile){
          flex-basis: 25%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .radio-wrapper {
        width:100px;
      }
      input[type="radio"]{
        width:100px;
        position:relative;
        z-index:9;
        opacity:0;
        cursor:pointer;

        


      }



      .active {
        cursor:pointer;
        transition:.5s;

         &:hover span.checkmark  {
          background:$primary;
          transition:.5s;
        }

        span.checkmark {
          position: absolute;
          width: 100%;
          border: none;
          border-radius: 40px;
          height: 45px;
          text-indent: 5px;
          box-sizing: border-box;
          padding: 5px;
          position: absolute;
          width: 100%;
          top: 0;
          background: $secondary;
          display:flex;
          align-items:center;
          cursor:pointer;




          img {
            width:14px;
            display:block;
            margin:auto;
            animation:tickIn .5s 1;
            animation-fill-mode:forwards;
            left:5px;
            position:relative;

            @keyframes tickIn {
              0%{
                opacity:0;
                left:5px;
              }
              50%{
                fill:#eee;
              }
              100%{
                opacity:1;
                left:0;
              }
            }
          }
      }
    }
      .non-active {
        cursor:pointer;
        transition:.5s;

         &:hover span.checkmark  {
          background:$primary;
          transition:.5s;
        }

        span.checkmark {
          position: absolute;
          width: 100%;
          border: none;
          border-radius: 40px;
          height: 45px;
          text-indent: 5px;
          box-sizing: border-box;
          padding: 5px;
          position: absolute;
          width: 100%;
          top: 0;
          background: $secondary;
          display:flex;
          align-items:center;
          cursor:pointer;

          &:hover {
            background:$primary;
            transition:.5s;
          }

          img {
            width:21px;
            display:none;
            margin:auto;
            animation:tickIn .25s 1;
            animation-fill-mode:forwards;
            left:5px;
              position:relative;

            @keyframes tickIn {
              0%{
                opacity:0;
                left:5px;
              }
              100%{
                opacity:1;
                left:0;
              }
            }
          }
      }

    
  }
}