@import '../../Scss/variables.scss';
@import '../../Scss/mixins.scss';

.menu-pdf-page{
  h3{
    color: #1675a9;
    font-size: 2rem;
    margin: 40px auto 0;
  }
  .menu-toggle{
    max-width: 600px;
    margin: 20px auto 40px;
    padding: 15px 5px;
    border-radius: 50px;
    background: #FF8672;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;

    @media (max-width:650px){
      // flex-direction: column;
    }

    .menu-toggle1,.menu-toggle2{
      font-family:"HuggyBold";
      background: none;
      border: none;
      color: #ffffff;
      margin: 0;
      font-size: 1.5rem;
      font-weight: bold;
      // flex-basis: 25%;
      transition: 1s;
      text-transform: uppercase;
      display: inline-block;
      width: auto;
      padding: 3px 18px;
      line-height: 140%;
      padding-top: 5px;

      &:hover{
        cursor: pointer;
        color:$primary;
        transition:.3s;
      }
    }
  }
  
  .bev-menu {
    &.hidden{
      display: none;
    }
  }
}

.Menu_pdf {
  width:70%;
  margin:auto;

  
  @media(max-width:650px){
    width:90%;
  }
}



