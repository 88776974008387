@import '../../Scss/variables.scss';
@import '../../Scss/mixins.scss';

.menu-pdf-wrapper {

	img {
		transition:.5s;
	}
	a:hover img {
		margin-left:15px;
		position:relative;
		transition:.6s;
	}
	h3 {
		font-size: 2rem;
    	color:$primary;

    	@media(max-width:600px){
    		width: 80%;
		    margin: auto;
		    margin-top: 25px;
    	}
	}
	.ubereats {
		max-width:200px;

		@media(max-width:600px){
			max-width:268px;
			margin-bottom:5px;
		}
	}
	.deliveroo,.menulogo {
		max-width:221px;
		margin-left:15px;

		@media(max-width:600px){
			margin-top:15px;
			max-width: 268px;
    		margin-left: 0;

		}
	}
	.menulogo {
		@media(max-width:600px){
			margin-bottom:25px;
		}
	}
}