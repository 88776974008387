@import '../../Scss/variables.scss';
@import '../../Scss/mixins.scss';

.component-header {


    span.mobile-off{
    display:block;

    @media(max-width:$large_tablet){

      display:none;
  
    }
  }
.covid-19-button.response-on {
  background:$primary;
}
.covid-19-button {
    text-align: center;
    background: #b22004;
    box-sizing: border-box;
    padding: 5px;
    color: #fff;
    font-weight: bold;
    font-size: .90rem;
    cursor: pointer;
    transition: .4s;
    top: -30px;
    position: relative;
    animation: covicButtonIn .5s 1;
    animation-fill-mode: forwards;
    position: fixed;
    width: 100%;
    left:0;
    z-index: 9;
    text-transform:uppercase;

    @keyframes covicButtonIn {
      0%{
        top:-30px;
      }
      100%{
        top:0;
      }
    }

    &:hover {
      background:$primary;
      transition:.5s;
    }


}
  .covid-response-pop {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 26px;
      z-index: 9;
      display:none;
      align-items:center;
      justify-content:center;

      a.view-menu-pop {
          background: $secondary;
          color: #fff;
          border-radius: 25px;
          padding: 10px 20px;
          text-decoration: none;
          padding: 15px 30px 10px 30px;
          transition:.4s;
          display:inline-block;
          text-transform:uppercase;

          &:hover {
            transform:scale(1.05);
            transition:.6s;
          }
      }

      .pop-list {
        text-align:left;
        color:#fff;
        border-top:1px solid rgba(255,255,255,0.2);
        border-bottom:1px solid rgba(255,255,255,0.2);
        padding-top:15px;
        padding-bottom:15px;
        list-style:none;

        @media(max-width:700px){
          padding-left:15px;
        }

        li {
          position:relative;

          &::before {
            content:"•";
            color:orange;
            position:relative;
            right:15px;
            font-size:1.25rem;
          }
        }
      }

      .covid-off-background {
        position:fixed;
        width:100%;
        height:100%;
        background:rgba(0, 49, 82, 0.6);
        top:0;
      }
      .cross-off {
        position:absolute;
        top:10px;
        right:10px;

        &:hover {
          transform:rotate(360deg);
          transition:.6s;
          cursor:pointer;
        }
      }

      .pop-info {
        max-width:800px;
        background:$primary;
        box-sizing:border-box;
        padding:25px;
        text-align:center;
        position:relative;

        @media(max-width:700px){
          max-width:95%;
          max-height:80vh;
          overflow-x:scroll;
        }

        p {
          color:#fff;
          font-size:2rem;
        }
      }
  }
.covid-response-pop.response-off {
  display:none;
}
 .covid-response-pop.response-on {
  display:flex;
  .pop-info {
    animation:popInfoIn .7s 1;
    animation-fill-mode:forwards;

    @keyframes popInfoIn {
      0%{
        top:25px;
        opacity:0;
      }
      100%{
        top:0;
        opacity:1;
      }
    }
  }
 }

.mobile-wrapper-show.mobile-on {
  padding-bottom:25px;
 p {
  color:$primary;
  margin-bottom:15px;
 }

}
.mobile-wrapper-show {
  display:none;
  cursor:pointer;


  p {

 
      font-size:2rem !important;
      color: #fff;
      text-decoration: none;
      font-weight: bold;
      flex-basis: 25%;
      transition: 1s;
      text-transform: uppercase;
      padding-top: 0px;
      display: inline-block;
      width: auto;
      padding: 5px 18px;
      margin:0;
      letter-spacing:1px;
      letter-spacing:1px;

      @media(max-width:$large_tablet){
        font-size:1.3rem;
        display:none;
      }

      &:hover {
        color:$primary;
        transition:.3s;
      }

      @media(max-width:$large_tablet){
    display:block;
  }
    
  }

  @media(max-width:$large_tablet){
    display:block;
  }
}

 

  // logo
  .logo-wrapper {
    display:flex;
    align-items:center;
    justify-content:center;
    margin:50px 0;
    min-height:250px;

    a{
      text-decoration: none;
    }

    h2{
      margin-top: 0;
      color: #1675A9;
      font-size: 2rem;
    }  
    img {
      width:270px;
      margin-top:40px;
      animation:logoIn .7s 1;
      animation-fill-mode:forwards;
      animation-delay:.3s;
      opacity:0;
      animation-fill-mode:forwards;
      top:-15px;
      position:relative;

      @keyframes logoIn {
        0%{
          opacity:0;
          top:-15px;
        }
        100%{
          opacity:1;
          top:0;
        }
      }

     @media(max-width:$large_tablet){
        width:200px;
     }
    }
  }
  // navigation menu
  .navigation-menu {
    max-width: 70%;
    margin: auto;
    background: #FF8672;
    padding: 15px 5px;
    border-radius: 50px;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: auto;

    @media(max-width:$large_tablet){
      max-width:80%;


    }

    a.active {

      color:$primary;
      .st1 {
       fill:$primary;
       transition:.4s;

        
      }
    }

    a {
      @include font-size(1.5);
      color: #fff;
      text-decoration: none;
      font-weight: bold;
      flex-basis: 25%;
      transition: 1s;
      text-transform: uppercase;
      padding-top: 5px;
      display: inline-block;
      width: auto;
      padding: 3px 18px;
      line-height:140%;
      padding-top:5px;

      @media(max-width:$large_tablet){
        @include font-size(1.4);
        display:block;
        animation:linkMobile .5s 1;
        animation-fill-mode:forwards;
        position:relative;

        @for $i from 1 to 1000 {
          &:nth-child(#{$i}){ 

            opacity:0;
            animation-delay: $i * 0.05s; 

          }
        }

        @keyframes linkMobile {
          0%{
            top:5px;
            opacity:0;
          }
          100%{
            top:0;
            opacity:1;
          }
        }
      }

      &:hover {
        color:$primary;
        transition:.3s;
      }
    }
    .link {
      width:70px;
      .st1 {
        fill:#fff;
        transition:1s;
      }

    }

  }
  // socials
  .socials-wrapper {
    max-width:70%;
    margin:auto;
    display:flex;
    align-items:center;
    justify-content:center;
    margin-top:40px;

    .twinfin-takeaway-wrap{
      border: 2px solid #1675A9;
      padding: 10px 20px;
      width: 200px;
      height: 100px;
      margin-right: 30px;   
      
      color: rgb(0,0,0);
      text-decoration: none;
      display: flex;
      justify-content: center;
      align-items: center;
      p{
        color: #1675A9;
        font-size: 1.2rem;
        letter-spacing: 1px;
      }
      &:hover{
        border-color: #FF8672;
        p{
          color: #FF8672;
        }
      }
    }
    .delivery-wrap {
      border: 2px solid #1675A9;
      padding: 10px 20px;
      width: 200px;
      height: 100px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      span{
        width: 100%;
        color: #1675A9;
        font-size: 1.2rem;
        letter-spacing: 1px;
      }
    }

    img {
      width:50px;
      transition:1s;
      
      &:hover {
        transform:scale(1.1);
        transition:.3s;
      }
    }

    @media (max-width:1100px){
      flex-wrap: wrap;
    }

    @media (max-width:600px){
      flex-wrap: wrap;
      .twinfin-takeaway-wrap{
        margin-right: 0;
        margin-bottom: 20px;
      }
      .delivery-wrap {
        margin-top: 0;
      }
    }
  }
}