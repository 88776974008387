.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}
.react-router-wrapper {

  opacity: 1;
  transition:  0.5s ease;
  position:relative;
  left:0;


  &.animIn {
    opacity: 1;
    margin-left:30px;
    width:100%;


  }

  &.animOut {
    opacity: 1;
    left:-30px;

    
  }
}

