@import '../../Scss/variables.scss';
@import '../../Scss/mixins.scss';


.landing-page {
  .landing-wrapper {
    max-width:70%;
    text-align:center;
    margin:auto;
    position:relative;
    animation:aboutIn .6s 1;
    animation-fill-mode:forwards;

    @media(max-width:$large_tablet){
      max-width:95%;
    }

    @keyframes aboutIn {
      0%{
        top:45px;
      }
      100%{
        top:0;
      }
    }

    .heading {
      width:75%;
      margin:auto;
      margin-top:15px;

      @media(max-width:$large_tablet){
        width:95%;
      }

      img {
        width:50%;
      }
    }
    .content {
        width: 70%;
        margin: auto;
        margin-bottom:200px;
        margin-top:50px;

        @media(max-width:$large_tablet){
          width:95%;
        }

        form {

          div {
            text-align:left;
          }
          input[type="text"],input[type="email"],input[type="number"], input[type="date"] {
             background-color:$secondary;
             color:#fff;
             font-weight:bold;
              @include font-size(1);

          }
          input, textarea {
            border:none;
            border-radius:40px;
            height:45px;
            text-indent:5px;
            box-sizing:border-box;
            padding:5px;
          }

          textarea {
            background-color:$secondary;
            height:250px;
          }

          label {
            font-weight:bold;
            color:$primary;
            display:block;
            text-transform:uppercase;
            margin:5px 5px 5px 18px;
          }
          .form-double-row {
            display:flex;
            flex-wrap:wrap;

            div {
              flex-basis:50%;

              @media(max-width:$large_tablet){
                flex-basis:100%;
              }


              input {
                width:calc(100% - 15px);
               
              }
            }
          }
          .form-row {
            width:100%;
            textarea {
              width:calc(100% - 15px);
              resize:none;
            }
            input[type="text"] {
              width:calc(100% - 15px);
            }
            .submit-button {
                background: $primary;
                padding: 0 40px;
                width: calc(50% - 15px);
                margin-top: 20px;
                color:$secondary;
                font-weight:bold;
                @include font-size(1);
                text-transform:uppercase;
                cursor:pointer;
                transition:1s;

                &:hover {
                  background:#eee;
                  transition:.3s;
                  animation:submitBlink .65s infinite;

                  @keyframes submitBlink {
                    0%{
                      color:$secondary;
                    }
                    50%{
                      color:$primary;
                    }
                    100%{
                      color:$secondary;
                    }
                  }
                }
            }
          }
        }
      
    }
  }
}