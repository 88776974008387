@import '../../Scss/variables.scss';
@import '../../Scss/mixins.scss';

.component-footer {
  .footer-wrapper {
    display:flex;
    max-width:70%;
    margin:auto;
    margin-bottom:50px;

     @media(max-width:$large_tablet){
        max-width:95%;
        display:block;
        text-align:center;

        .logo-desktop, .logo-title {
          display:none;
        }

      }


    .link {
      cursor:pointer;
    }

      div {
        .footer-logo {
          max-height:200px;
        }

        .location-desktop {
          max-height:125px
        }
        h4 {
          color:$secondary;
          font-weight:normal;
          @include font-size(1.5);
        }

        a {
          color:$primary;
          text-decoration:none;
           @include font-size(1.1);
        }
      }

    }
    .col-one,.col-three {
      flex-basis:80%;

      h5 {
        @include font-size(2);
        text-transform:uppercase;
        color:$secondary;
        margin-top:0;
        margin-bottom:5px;
        letter-spacing:1px;
      }

      ul {
        padding:0;
        list-style:none;

        li,a {
          @include font-size(1.5);
          letter-spacing:1px;
          line-height:110%;
          transition:1s;
          color:$primary;
          text-transform:uppercase;
        }

        a:hover {
          color:$secondary;
          transition:.3s;
        }
      }
    }
    .col-two {
      flex-basis:20%;
      text-align: center;
      p{
        margin-top: 0;
        color: #1675A9;
        font-size: 1.7rem;
      }
    }
    .col-three {
      flex-basis:20%;
      text-align:right;

      @media(max-width:$large_tablet){
        text-align:center;
      }

      ul {
        text-align:right;

        @media(max-width:$large_tablet){
          text-align:center;
        }
      }

      .logo-mobile {
        display:none;

        @media(max-width:$large_tablet){
          display:block;
          width:120px;
          margin:auto;
          margin-top:25px;
        }
      }

      .logo-title-mobile{
        display:none;

        @media(max-width:$large_tablet){
          display:block;
          width:120px;
          margin:auto;
          margin-top:0;
          color: #1675A9;
          font-size: 1rem;
        }
      }

      .website-by, .website-by a {
        @include font-size(.8);
        margin-top:35px;
      }

    }


}