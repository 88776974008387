@import '../../Scss/variables.scss';
@import '../../Scss/mixins.scss';


.contact-page {

  .sub-info {
      @include font-size(1.5);
    letter-spacing: 1px;
    text-transform:uppercase;
    color:$primary;
    margin-top:0;padding-top:0;
  }


  .contact-wrapper {
    max-width:70%;
    text-align:center;
    margin:auto;
    position:relative;
    animation:aboutIn .6s 1;
    animation-fill-mode:forwards;

    @media(max-width:$large_tablet){
      max-width:95%;
    }

    @keyframes aboutIn {
      0%{
        top:45px;
      }
      100%{
        top:0;
      }
    }

    input:focus, textarea:focus {
      box-shadow:none;
      outline:none;
    }

    .status.status-gone {
      display:none;
    }

    .status {
      animation:statusIn .6s 1;
      animation-fill-mode:forwards;
      opacity:0;
      position:relative;

     

      p {
        color:$default;
        letter-spacing:1px;
        font-size:1rem;
        text-align:left;
        text-transform:uppercase;
        background:$primary;
        padding:15px 15px 9px 15px;
        border-radius:20px;
        transition:1s;

        &:hover {
          background:$default;
          color:$primary;
          transition:.3s;
          cursor:pointer;
        }
      }

      @keyframes statusIn {
        0%{
          top:25px;
          opacity:0;
        }
        100%{
          top:0px;
          opacity:1;
        }
      }
    }

    .heading {
      width:75%;
      margin:auto;
      margin-top:15px;

      @media(max-width:$large_tablet){
        width:95%;
      }

      img {
        width:50%;
      }
    }
    .content {
        width: 70%;
        margin: auto;
        margin-bottom:200px;
        margin-top:50px;

        @media(max-width:$large_tablet){
          width:95%;
          margin-bottom:100px;
        }

        form {

          div {
            text-align:left;
          }
          input[type="text"],input[type="email"],input[type="number"], input[type="date"] {
             background-color:$secondary;
             color:#fff;
             font-weight:bold;
              @include font-size(1);

          }
          input, textarea {
            border:none;
            border-radius:40px;
            height:45px;
            text-indent:5px;
            box-sizing:border-box;
            padding:5px;
          }

          textarea {
            background-color:$secondary;
            height:250px;
             color:#fff;
             font-weight:bold;
              @include font-size(1);
              text-indent:20px;
              padding-top:15px;
          }

          label {
            color:$primary;
            display:block;
            text-transform:uppercase;
            margin:8px 5px 5px 18px;
          }
          .form-double-row {
            display:flex;
            flex-wrap:wrap;

            .react-datepicker-wrapper,.react-datepicker__input-container {
              flex-basis:calc(100% - 15px);
              display:flex;

               @media(max-width:$mobile){
                  flex-basis:100%;
                }

              input {
                width:100%;
              }


            }

            div {
              flex-basis:50%;

               .react-datepicker__current-month {
                text-align:center;
              }
              .react-datepicker__header {
                  background: $primary;
                  color: #fff;
              }
              .react-datepicker__day-names {
                text-align:center;
              }

              .react-datepicker__day-name {
                color:$default;
                font-weight:bold;
                text-align:center;
              }
              .react-datepicker__current-month {
                color:$default;
              }
              .react-datepicker__day {
                  text-align: center;
                  color:$default;
              }
              .react-datepicker__month {
                background:$primary;
                margin: 0;
                padding: 0.4rem;
              }
              .react-datepicker__day--selected,.react-datepicker__day--today {
                  background: $secondary;
              }
              .react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover {
                background:$secondary;
                transition:.4s;
              }

              @media(max-width:$large_tablet){
                flex-basis:100%;
              }


              input {
                width:calc(100% - 15px);

                @media(max-width:$mobile){
                  width:100%;
                }
               
              }
            }
          }
          .form-row {
            width:100%;
            textarea {
              width:calc(100% - 15px);
              resize:none;

               @media(max-width:$mobile){
                  width:100%;
                }
            }
            input[type="text"] {
              width:calc(100% - 15px);

               @media(max-width:$mobile){
                  width:100%;
                }
            }
            .submit-button {
                background: $primary;
                padding: 0 40px;
                width: calc(50% - 15px);
                margin-top: 20px;
                color:$secondary;
                font-weight:bold;
                @include font-size(1.5);
                padding-top:5px;
                letter-spacing:1px;
                text-transform:uppercase;
                cursor:pointer;
                transition:1s;


                &:hover {
                  background:#eee;
                  transition:.3s;
                  animation:submitBlink .65s infinite;

                  @keyframes submitBlink {
                    0%{
                      color:$secondary;
                    }
                    50%{
                      color:$primary;
                    }
                    100%{
                      color:$secondary;
                    }
                  }
                }
            }
          }
        }
      
    }
  }
}