.full-bleed-pop {
    position: fixed;
    bottom: 0;
    width: 400px;
    right: -100%;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display:flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    opacity:0;
    animation:fullBleedPopIn .6s 1;
    animation-fill-mode:forwards;
    animation-delay:3.5s;
    top:15px;
}
.full-bleed-pop.pop-show {
    display:none;
}
.full-bleed-pop a {
  text-decoration:none;
}
@keyframes fullBleedPopIn {
  0%{
    opacity:0;
    top:15px;
  }
  100%{
    opacity:1;
    top:0;
  }
}
.full-bleed-pop.pop-hide {
  display:none;
}
.full-bleed-pop .filter {
    position: absolute;
    width: 100% inherit;
    width: 100%;
    height: 100%;
    left: 0;
    top: bac;
    top: 0;
    background: rgba(0,0,0,0.4);
}
.the-pop-up {
    max-width: 500px;
    text-align: center;
    position:relative;
}
.the-pop-up button {
    width: 100%;
    border: none;
    position: relative;
    top: -10px;
    padding: 5px;
    box-sizing: border-box;
    background: #f68972;
    color: #fff;
    font-family: "HuggyBold",arial, sans-serif;
    letter-spacing: 1px;
    font-size: norm;
    font-size: 20px;
    font-weight: lighter;
    padding-top: 9px;
}
img#close-pop-two {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 35px;
    margin-right: 15px;
    cursor: pointer;
    transition:.6s;
}
img#close-pop-two:hover {
  transform:rotate(180deg);
  transition:.6s;
}
.full-bleed-pop h4 {
    background: red;
    margin-bottom: 0;
    padding-bottom: 7px;
    padding-top: 12px;
    color: #fff;
    letter-spacing: 1px;
    font-size: 20px;
    background: #fff;
    color: #1676a9;
    text-align:left;
    padding-left:15px;
}
.full-bleed-pop img.banner {
    width: 100%;
}